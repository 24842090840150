import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"

const AboutPage = ({ data }) => (
  <Layout
    headerImg={data.header.childImageSharp.fluid}
    footerImg={data.tourism.childImageSharp.fluid}
  >
    <SiteMetadata title="About" description="Sample description" />

    <div className="bg-gray-100">
      <div className="container py-12 lg:pb-16">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 xl:w-3/5 pb-8 md:pb-0">
            <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              About
            </h1>
            <div className="mt-4 leading-loose">
              Hello Alice is all about community engagement. We tune in to your
              everyday needs by helping you free up some of your valuable time
              so you can do more of the things you love in your life. In fact,
              we like to support everyone in the community, including NDIS
              Participants.
              <br />
              <br />
              Together, we work with you to help you develop and manage a happy
              and healthy lifestyle including connecting you with community,
              events, and culture in Central Australia.
              <br />
              <br />
              Big or small, with our variety of support services, we are here to
              help you get into the rhythm of life.
              <br />
              <br />
              <b>0488 992 781</b>
              <br />
              <a
                href="mailto:michelle@helloalice.com.au"
                className="border-b border-gray-500 hover:border-blue-600 hover:text-blue-600"
              >
                michelle@helloalice.com.au
              </a>
              <br />
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-2/5 md:pl-12">
            <Img
              fluid={data.author.childImageSharp.fluid}
              alt="Lady holding a medal with three men smiling"
              className="rounded-md shadow-md"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default AboutPage

export const query = graphql`
  query {
    author: file(relativePath: { eq: "author.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 480, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    header: file(relativePath: { eq: "icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tourism: file(relativePath: { eq: "tourism-logo.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 480, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
